// src/components/assistant/ConfigurationTab.js
import React, { useState } from 'react';
import {
  Grid, GridItem, VStack, FormControl, FormLabel, Input, Slider, SliderTrack,
  SliderFilledTrack, SliderThumb, Select, Text, Checkbox, HStack, Button, Tooltip,
  Flex,Heading
} from '@chakra-ui/react';
import { MdAutorenew } from 'react-icons/md';


const ConfigurationTab = ({
  formState,
  handleInputChange,
  handleSliderChange,
  handleVoiceRefresh,
  handleCheckboxChange,
  setIsDialogOpen,
  voiceLoading,
  voices,
  voiceRef,
  handleUrlChange,
  formErrors
}) => {
    return (
    <Grid templateColumns={{
      base: "repeat(1, 1fr)",
      md: "repeat(1, 1fr)",
      lg: "repeat(2, 1fr)"
    }} gap={6}>
      {/* Left Column */}
      <GridItem>
        <VStack spacing={4} align="stretch">
        <Flex bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900" direction="column">
        <FormControl isRequired pb={5}>
            <Heading size="sm" pb={5}>Cartesia Voice Setting</Heading>
            <FormLabel>Voices</FormLabel>
            <Text fontSize="sm">To preview the voice, login to <a rel="noreferrer" target="_blank" href="https://play.cartesia.ai/">https://play.cartesia.ai/</a></Text>
            <HStack spacing='5px'>
                <Select 
                ref={voiceRef}
                name="cartesiaVoiceId" bg="gray.900" color="gray.100"
                    value={formState.cartesiaVoiceId}
                    onChange={handleInputChange} required={true} colorScheme="orange">
                        {voices.map((voice) => (
                            <option key={voice.id} value={voice.id}>
                            {voice.name}
                        </option>
                        ))}

                </Select>
                <Tooltip label="Reload from Cartesia Voices" aria-label="Refresh Cartesia Voices">
                    <Button onClick={handleVoiceRefresh} variant="ghost" p={0} disabled={voiceLoading}>
                        <MdAutorenew size="1.5rem" color="black" style={{
                            animation: voiceLoading ? 'spin 1s linear infinite': 'none'
                        }}/>
                    </Button>
                </Tooltip>
            </HStack>
        </FormControl>
        <FormControl isRequired>
            <Flex alignItems="center">
                <Text mr={2}>Voice Speed:</Text>
                <Text color="brand.400">{formState.voiceSpeed}</Text>
            </Flex>
            <Slider
                aria-label="voice-speed-slider"
                value={formState.voiceSpeed}
                onChange={(val) => handleSliderChange(val, 'voiceSpeed')}
                min={-1}
                max={1}
                step={0.1}
            >
                <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="blue.400" />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </FormControl>

        </Flex>
        
        <Flex bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900" direction="column">
            <Heading size="sm">Webhook</Heading>
            
            <Grid templateColumns="1fr 5fr" gap={4} alignItems="center" pt={5}>
                <GridItem>
                    <FormLabel>URL</FormLabel>
                </GridItem>
                <GridItem>
                    <Input
                        name="webhookUrl"
                        placeholder="Enter webhook URL"
                        value={formState.webhookUrl || ''}
                        onChange={(e) => handleUrlChange(e)}
                        type="url"
                        bg="gray.900"
                        color="gray.200"
                    />
                    {formErrors?.url && <Text color="red.500">{formErrors?.url}</Text>}
                </GridItem>
                <GridItem>
                <FormLabel>Username</FormLabel>
                </GridItem>
                <GridItem>
                <Input
                        name="webhookUser"
                        placeholder="Enter webhook username"
                        value={formState.webhookUser  || ''}
                        onChange={(e) => handleInputChange(e)}
                        type="text"
                        bg="gray.900"
                        color="gray.200"
                    />
                </GridItem>
                <GridItem>
                <FormLabel>Password</FormLabel>
                </GridItem>
                <GridItem>
                <Input
                        name="webhookPassword"
                        placeholder="Enter webhook password"
                        value={formState.webhookPassword  || ''}
                        onChange={(e) => handleInputChange(e)}
                        type="password"
                        bg="gray.900"
                        color="gray.200"
                    />
                </GridItem>
            </Grid>
          </Flex>

          <Flex bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900" direction="column" gap={5}>
            <Heading size="sm">Auto Hangup Setting</Heading>

            {/* hang up timeout */}
            <Flex direction="column">
                <Flex alignItems="center">
                    <Text mr={2}>Max Call Time in seconds:</Text>
                    <Text color="brand.400">{formState.hangupTimeout}</Text>
                </Flex>
                <Slider
                    aria-label="hangup-timeout-slider"
                    value={formState.hangupTimeout}
                    onChange={(val) => handleSliderChange(val, 'hangupTimeout')}
                    min={30}
                    max={600}
                    step={10}
                >
                    <SliderTrack bg="gray.600">
                    <SliderFilledTrack bg="blue.400" />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>
            </Flex>
            <FormControl>
                <FormLabel>Hangup Trigger Words</FormLabel>
                <Input
                    placeholder="Enter words to trigger hangup. Separate multiple trigger with commas"
                    name="hangupTrigger"
                    value={formState.hangupTrigger || ''}
                    onChange={handleInputChange}
                    bg="gray.900"
                    color="gray.200"
                />
            </FormControl>
        </Flex>
          
        </VStack>
      </GridItem>
     
      <GridItem>
        
        <VStack spacing={4} align="stretch">
        <Flex bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900" direction="column">
        <FormControl isRequired>
        <Heading size="sm">Deepgram SST Setting</Heading>
            <FormLabel pt={5}>Model ID</FormLabel>
            <Select name="transcriptionModelId" bg="gray.900" color="gray.100"
            value={formState.transcriptionModelId || 'nova-2'}
            onChange={handleInputChange} required={true} colorScheme="orange">
                <option value='nova-2'>Nova-2</option>
                <option value='base'>Enhanced</option>
                <option value='enhanced'>Base</option>
            </Select>
            
          
        </FormControl>
        </Flex>

        <Flex bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900" direction="column">
            <Heading size="sm" pb={5}>Idle Setting</Heading>
            <FormControl isRequired pb={5}>
                <FormLabel>Idle Message</FormLabel>
                <Input
                    placeholder="Enter idle message"
                    name="idleMessage"
                    value={formState.idleMessage || ''}
                    onChange={handleInputChange}
                    bg="gray.900"
                    color="gray.200"
                />
            </FormControl>
            <Flex alignItems="center">
                <Text mr={2}>Idle Timeout in seconds:</Text>
                <Text color="brand.400">{formState.callTimeout || 20}</Text>
            </Flex>
            <Slider
                aria-label="call-timeout-slider"
                value={formState.callTimeout || 20}
                onChange={(val) => handleSliderChange(val, 'callTimeout')}
                min={5}
                max={60}
                step={1}
            >
                <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="blue.400" />
                </SliderTrack>
                <SliderThumb />
            </Slider>
          </Flex>

          <Flex bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900" direction="column">
            <Heading size="sm" pb={5}>Interruption Handling</Heading>
            <Flex alignItems="center">
                <FormLabel flex="0 0 50%">Number of Words Before Stop Talking</FormLabel>
                <Input flex="0 0 30%" 
                type="number" 
                name="wordsInterruption" 
                placeholder="0" min="0" max="10" step="1" 
                bg="gray.900"
                color="gray.200"
                value={formState.wordsInterruption || 3}
                onChange={handleInputChange}
                />
            </Flex>
            
          </Flex>

          <Checkbox
            name="attachConversationHistory"
            isChecked={formState.attachConversationHistory || false}
            onChange={handleCheckboxChange}
          >
            Attached Last Conversation History
          </Checkbox>

          <Checkbox
            name="twilioRecording"
            isChecked={formState.twilioRecording || false}
            onChange={handleCheckboxChange}
          >
            Enable Call Recording (There is additional charge for this. Refer to Twilio pricing)
          </Checkbox>
          

          {/* Delete button */}
          <Button
            colorScheme="red"
            onClick={() => setIsDialogOpen(true)}
            size="sm"
          >
            Delete
          </Button>
        </VStack>
      </GridItem>
    </Grid>
)};

export default ConfigurationTab;